import { flushSync } from "react-dom"
import { IconImageComponent, encode } from "../../../../common/utils"
import carousal_buttons from "./assets/carousal_buttons.png"
import carousel from "./assets/carousel.svg"
import coachmark from "./assets/coachmark.png"
import inapp_custom_html from "./assets/inapp_custom_html.png"
import inapp_footer from "./assets/inapp_footer.png"
import inapp_fullscreen_modal from "./assets/inapp_fullscreen_modal.png"
import inapp_header from "./assets/inapp_header.png"
import inapp_modal_popup from "./assets/inapp_modal_popup.png"
import inapp_videos from "./assets/inapp_videos.svg"
import modal_text from "./assets/modal_text.svg"
import nudge from "./assets/nudge.png"
import spotlight from "./assets/spotlight.png"
import tooltip_short_menu from "./assets/tooltip_short_menu.png"
import tooltip_with_image from "./assets/tooltip_with_image.png"
import tooltip_with_text from "./assets/tooltip_with_text.png"
import {
  ACTION_CLASSES,
  BADGE_DISPLAY_TYPES,
  COACHMARK_DISPLAY_TYPES,
  INAPP_LAYOUT_TYPES,
  NEXT_BUTTON_STATE,
  ONBOARDING_TOOLTIP_LAYOUT_TYPES,
  SEARCH_CRITERIA_TYPES,
  SET_VIEW_MAP,
  TOOLTIP_LAYOUT_TYPES,
  WEB_LAUNCH_MODE,
} from "./constants"

export const getActionClassInfo = ({
  action_class = ACTION_CLASSES.TOOLTIP,
  layout_type,
  display_type,
  isOnboardingMode = false,
}) => {
  const styles = { width: "auto", height: 40, marginRight: 10, marginLeft: 18 }

  if (!isOnboardingMode) {
    // Tooltips
    if (action_class === ACTION_CLASSES.TOOLTIP) {
      switch (layout_type) {
        // Basic Text
        case TOOLTIP_LAYOUT_TYPES.DEFAULT:
          return {
            image: IconImageComponent(tooltip_with_text, "Basic Text", styles),
            name: TOOLTIP_LAYOUT_TYPES.DEFAULT,
          }

        // Basic Text with Image
        case TOOLTIP_LAYOUT_TYPES.IMAGE_TEXT_BUTTONS:
          return {
            image: IconImageComponent(
              tooltip_with_image,
              "Basic Text with Image",
              styles,
            ),
            name: TOOLTIP_LAYOUT_TYPES.IMAGE_TEXT_BUTTONS,
          }

        // Short Menu
        case TOOLTIP_LAYOUT_TYPES.ACTION_BUTTONS:
          return {
            image: IconImageComponent(tooltip_short_menu, "Short Menu", styles),
            name: TOOLTIP_LAYOUT_TYPES.ACTION_BUTTONS,
          }

        default:
          return {
            image: IconImageComponent(tooltip_with_text, "Basic Text", styles),
            name: TOOLTIP_LAYOUT_TYPES.DEFAULT,
          }
      }
    }

    // InApps
    if (action_class === ACTION_CLASSES.INAPP) {
      switch (layout_type) {
        // Header
        case INAPP_LAYOUT_TYPES.HEADER:
          return {
            image: IconImageComponent(inapp_header, "Header", styles),
            name: INAPP_LAYOUT_TYPES.HEADER,
          }

        // Footer
        case INAPP_LAYOUT_TYPES.FOOTER:
          return {
            image: IconImageComponent(inapp_footer, "Footer", styles),
            name: INAPP_LAYOUT_TYPES.FOOTER,
          }

        // Custom HTML
        case INAPP_LAYOUT_TYPES.CUSTOM_HTML:
          return {
            image: IconImageComponent(inapp_custom_html, "Custom HTML", styles),
            name: INAPP_LAYOUT_TYPES.CUSTOM_HTML,
          }

        // Full Screen Modal
        case INAPP_LAYOUT_TYPES.FULL_SCREEN_MODAL:
          return {
            image: IconImageComponent(
              inapp_fullscreen_modal,
              "Full Screen Cover",
              styles,
            ),
            name: INAPP_LAYOUT_TYPES.FULL_SCREEN_MODAL,
          }

        // Popout Modal
        case INAPP_LAYOUT_TYPES.POPOUT_MODAL:
          return {
            image: IconImageComponent(inapp_modal_popup, "Modal Popup", styles),
            name: INAPP_LAYOUT_TYPES.POPOUT_MODAL,
          }

        // Carousel
        case INAPP_LAYOUT_TYPES.CAROUSEL:
          return {
            image: IconImageComponent(carousel, "Carousel", styles),
            name: INAPP_LAYOUT_TYPES.CAROUSEL,
          }

        // Videos
        case INAPP_LAYOUT_TYPES.VIDEOS:
          return {
            image: IconImageComponent(inapp_videos, "Video PIP", styles),
            name: display_type,
          }

        // Modal
        case "modal":
          return {
            image: IconImageComponent(modal_text, "Modal", styles),
            name: INAPP_LAYOUT_TYPES.MODAL,
          }
        default:
          return { image: "", name: "" }
      }
    }

    // Nudge
    if (action_class === ACTION_CLASSES.NUDGE) {
      switch (display_type) {
        case BADGE_DISPLAY_TYPES.LEGACY:
          return {
            image: IconImageComponent(nudge, "Badge", styles),
            name: BADGE_DISPLAY_TYPES.LEGACY,
          }
        default:
          return {
            image: IconImageComponent(nudge, "Badge", styles),
            name: BADGE_DISPLAY_TYPES.LATEST,
          }
      }
    }

    // Coachmark
    if (action_class === ACTION_CLASSES.COACHMARK) {
      switch (display_type) {
        case COACHMARK_DISPLAY_TYPES.LEGACY:
          return {
            image: IconImageComponent(coachmark, "Coachmark", styles),
            name: COACHMARK_DISPLAY_TYPES.LEGACY,
          }
        default:
          return {
            image: IconImageComponent(coachmark, "Coachmark", styles),
            name: COACHMARK_DISPLAY_TYPES.LATEST,
          }
      }
    }

    // Inline
    if (action_class === ACTION_CLASSES.INLINE) {
      switch (layout_type) {
        case "modal":
        default:
          return {
            image: IconImageComponent(modal_text, action_class, styles),
            name: "Tooltip",
          }
      }
    }
  } else {
    // Onboarding Tooltips
    if (layout_type === ONBOARDING_TOOLTIP_LAYOUT_TYPES.CAROUSAL_BUTTONS) {
      return {
        image: IconImageComponent(carousal_buttons, "Carousal Buttons", styles),
        name: "Carousal Buttons",
      }
    }

    // Onboarding Spotlights
    return {
      image: IconImageComponent(spotlight, "Spotlight", styles),
      name: "Spotlight",
    }
  }
}

export const getNextButtonConfig = (config) => {
  if (config.next)
    return {
      next: {
        ...config.next,
        text: config.lastStepText,
      },
    }
  else
    return {
      next: {
        ...NEXT_BUTTON_STATE.next,
        text: config.lastStepText,
      },
    }
}

const URL_PARAMS = ["_a", "_p", "_x", "_o", "_r"]

export const generateQRCodeURL = (
  url,
  app_id,
  volatile_id = "",
  mode = WEB_LAUNCH_MODE.VIEW_SELECTION,
  selection_mode = "v1",
) => {
  if (!url || !url.trim || url.trim() === "") {
    return
  }

  // const urlObj = new URL(url)
  const urlObj = [
    "7cc77eb4-548d-41d8-afc9-364d564627ee",
    "fa208fab-4acf-4f68-ad25-5eca5fae1731",
    "0cf48190-6796-4455-9374-365e87e0f14b",
    "741523bd-0ab6-4919-a580-227b4c9fd8c4",
  ].includes(app_id)
    ? new URL("chrome-extension://pmlonnmjiemfplffkjbindboeiffamji/open.html")
    : new URL(url)
  urlObj.searchParams.append(
    URL_PARAMS[Math.round(Math.random() * 4)],
    encode(
      app_id,
      JSON.stringify({
        app_id,
        expiry: Date.now() + 5 * 60 * 1000,
        volatile_id,
        mode,
        selection_mode,
      }),
    ),
  )
  if (
    [
      "7cc77eb4-548d-41d8-afc9-364d564627ee",
      "fa208fab-4acf-4f68-ad25-5eca5fae1731",
      "0cf48190-6796-4455-9374-365e87e0f14b",
      "741523bd-0ab6-4919-a580-227b4c9fd8c4",
    ].includes(app_id)
  ) {
    urlObj.searchParams.append("url", url)
    urlObj.searchParams.append("app_id", app_id)
  }
  return urlObj.toString()
}

export const openURLInNewTab = (
  url,
  app_id,
  volatile_id = "",
  mode = WEB_LAUNCH_MODE.VIEW_SELECTION,
  selection_mode = "v1",
) => {
  const urlObj = generateQRCodeURL(
    url,
    app_id,
    volatile_id,
    mode,
    selection_mode,
  )

  window.open(urlObj.toString(), "_blank", "noopener noreferrer")
}

export const getUI = (index, uis, isExperiment = false) => {
  if (isExperiment)
    return uis.filter((eachUI) => eachUI.index === index)[0] || uis[index]
  else return uis[index]
}

export const transformThemeBasedOnCurrentUI = (
  templateUi,
  themeUi,
  isOnboardingMode = false,
) => {
  const { layout_type, display_type, action_class } = templateUi
  const {
    ui: {
      containerConfigs: {
        container_color,
        corner_radius,
        border_stroke: { stroke_color, stroke_width, stroke_style },
        close_icon: {
          close_icon_color,
          height,
          width,
          offset_x,
          offset_y,
          close_icon_type,
          close_icon_fill_color,
          close_icon_fill_shape,
        },
        // inside_container: {
        //   container_color: inside_container_color,
        //   corner_radius: inside_container_corner_radius,
        // },
      },
      textConfigs: {
        description: {
          desc_font_color,
          desc_font_family,
          desc_font_size,
          desc_font_style,
          desc_font_weight,
          desc_alignment,
        },
        title: {
          title_font_color,
          title_font_family,
          title_font_size,
          title_font_style,
          title_alignment,
          title_font_weight,
        },
      },
      buttonConfigs,
    },
  } = themeUi
  if (!isOnboardingMode) {
    // InApps
    if (action_class === ACTION_CLASSES.INAPP) {
      switch (layout_type) {
        // Header
        case INAPP_LAYOUT_TYPES.HEADER:
          return {
            ...templateUi,
            bg_color: container_color,
            border_radius: corner_radius,
            text_config: {
              ...templateUi.text_config,
              color: desc_font_color,
              font_family: desc_font_family,
              size: desc_font_size,
              style: desc_font_style,
            },
            title_config: {
              ...templateUi.title_config,
              color: title_font_color,
              font_family: title_font_family,
              size: title_font_size,
              style: title_font_style,
            },
            buttons_config: Array.from(
              { length: templateUi.buttons_config.length },
              (_, i) => i,
            ).map((_, idx) => {
              const currentThemeBtn = buttonConfigs[idx]
              const currentBtn = templateUi.buttons_config[idx]
              return {
                ...currentBtn,
                color:
                  currentThemeBtn.button_type === "filled"
                    ? currentThemeBtn.filled_button_color
                    : "#",
                borders: {
                  ...currentBtn.borders,
                  radius: currentThemeBtn.corner_radius,
                  stroke_color: currentThemeBtn.border_color,
                  stroke_width: currentThemeBtn.border_width,
                },
                text_config: {
                  ...currentBtn.text_config,
                  color: currentThemeBtn.button_text.font_color,
                  font_family: currentThemeBtn.button_text.font_family,
                  size: currentThemeBtn.button_text.font_size,
                  style: currentThemeBtn.button_text.font_style,
                },
              }
            }),
          }

        // Footer
        case INAPP_LAYOUT_TYPES.FOOTER:
          return {
            ...templateUi,
            bg_color: container_color,
            border_radius: corner_radius,
            text_config: {
              ...templateUi.text_config,
              color: desc_font_color,
              font_family: desc_font_family,
              size: desc_font_size,
              style: desc_font_style,
            },
            title_config: {
              ...templateUi.title_config,
              color: title_font_color,
              font_family: title_font_family,
              size: title_font_size,
              style: title_font_style,
            },
            buttons_config: Array.from(
              { length: templateUi.buttons_config.length },
              (_, i) => i,
            ).map((_, idx) => {
              const currentThemeBtn = buttonConfigs[idx]
              const currentBtn = templateUi.buttons_config[idx]
              return {
                ...currentBtn,
                color:
                  currentThemeBtn.button_type === "filled"
                    ? currentThemeBtn.filled_button_color
                    : "#",
                borders: {
                  ...currentBtn.borders,
                  radius: currentThemeBtn.corner_radius,
                  stroke_color: currentThemeBtn.border_color,
                  stroke_width: currentThemeBtn.border_width,
                },
                text_config: {
                  ...currentBtn.text_config,
                  color: currentThemeBtn.button_text.font_color,
                  font_family: currentThemeBtn.button_text.font_family,
                  size: currentThemeBtn.button_text.font_size,
                  style: currentThemeBtn.button_text.font_style,
                },
              }
            }),
          }

        // Custom HTML
        case INAPP_LAYOUT_TYPES.CUSTOM_HTML:
          return { ...templateUi }

        // Full Screen Modal
        case INAPP_LAYOUT_TYPES.FULL_SCREEN_MODAL:
          return {
            ...templateUi,
            bg_color: container_color,
            border_radius: corner_radius,
            text_config: {
              ...templateUi.text_config,
              color: desc_font_color,
              font_family: desc_font_family,
              size: desc_font_size,
              style: desc_font_style,
            },
            title_config: {
              ...templateUi.title_config,
              color: title_font_color,
              font_family: title_font_family,
              size: title_font_size,
              style: title_font_style,
            },
            buttons_config: Array.from(
              { length: templateUi.buttons_config.length },
              (_, i) => i,
            ).map((_, idx) => {
              const currentThemeBtn = buttonConfigs[idx]
              const currentBtn = templateUi.buttons_config[idx]
              return {
                ...currentBtn,
                color:
                  currentThemeBtn.button_type === "filled"
                    ? currentThemeBtn.filled_button_color
                    : "#",
                borders: {
                  ...currentBtn.borders,
                  radius: currentThemeBtn.corner_radius,
                  stroke_color: currentThemeBtn.border_color,
                  stroke_width: currentThemeBtn.border_width,
                },
                text_config: {
                  ...currentBtn.text_config,
                  color: currentThemeBtn.button_text.font_color,
                  font_family: currentThemeBtn.button_text.font_family,
                  size: currentThemeBtn.button_text.font_size,
                  style: currentThemeBtn.button_text.font_style,
                },
              }
            }),
          }
        // Popout Modal
        case INAPP_LAYOUT_TYPES.POPOUT_MODAL:
          return {
            ...templateUi,
            bg_color: container_color,
            border_radius: corner_radius,
            text_config: {
              ...templateUi.text_config,
              color: desc_font_color,
              font_family: desc_font_family,
              size: desc_font_size,
              style: desc_font_style,
            },
            title_config: {
              ...templateUi.title_config,
              color: title_font_color,
              font_family: title_font_family,
              size: title_font_size,
              style: title_font_style,
            },
            buttons_config: Array.from(
              { length: templateUi.buttons_config.length },
              (_, i) => i,
            ).map((_, idx) => {
              const currentThemeBtn = buttonConfigs[idx]
              const currentBtn = templateUi.buttons_config[idx]
              return {
                ...currentBtn,
                color:
                  currentThemeBtn.button_type === "filled"
                    ? currentThemeBtn.filled_button_color
                    : "#",
                borders: {
                  ...currentBtn.borders,
                  radius: currentThemeBtn.corner_radius,
                  stroke_color: currentThemeBtn.border_color,
                  stroke_width: currentThemeBtn.border_width,
                },
                text_config: {
                  ...currentBtn.text_config,
                  color: currentThemeBtn.button_text.font_color,
                  font_family: currentThemeBtn.button_text.font_family,
                  size: currentThemeBtn.button_text.font_size,
                  style: currentThemeBtn.button_text.font_style,
                },
              }
            }),
          }

        // Carousel
        case INAPP_LAYOUT_TYPES.CAROUSEL:
          return {
            ...templateUi,
            bg_color: container_color,
            border_radius: corner_radius,
            text_config: {
              ...templateUi.text_config,
              color: desc_font_color,
              font_family: desc_font_family,
              size: desc_font_size,
              style: desc_font_style,
            },
            title_config: {
              ...templateUi.title_config,
              color: title_font_color,
              font_family: title_font_family,
              size: title_font_size,
              style: title_font_style,
            },
            buttons_config: Array.from(
              { length: templateUi.buttons_config.length },
              (_, i) => i,
            ).map((_, idx) => {
              const currentThemeBtn = buttonConfigs[idx]
              const currentBtn = templateUi.buttons_config[idx]
              return {
                ...currentBtn,
                color:
                  currentThemeBtn.button_type === "filled"
                    ? currentThemeBtn.filled_button_color
                    : "#",
                borders: {
                  ...currentBtn.borders,
                  radius: currentThemeBtn.corner_radius,
                  stroke_color: currentThemeBtn.border_color,
                  stroke_width: currentThemeBtn.border_width,
                },
                text_config: {
                  ...currentBtn.text_config,
                  color: currentThemeBtn.button_text.font_color,
                  font_family: currentThemeBtn.button_text.font_family,
                  size: currentThemeBtn.button_text.font_size,
                  style: currentThemeBtn.button_text.font_style,
                },
              }
            }),
          }

        // Videos
        case INAPP_LAYOUT_TYPES.VIDEOS:
          return {
            ...templateUi,
            bg_color: container_color,
          }

        // Modal
        case "modal":
          return {
            ...templateUi,
            bg_color: container_color,
            border: {
              ...templateUi.border,
              color: stroke_color,
              width: stroke_width,
              style: stroke_style,
            },
            buttons: {
              ...templateUi.buttons,
              bottom: Array.from(
                { length: templateUi.buttons?.bottom?.length },
                (_, i) => i,
              ).map((_, idx) => {
                const currentThemeBtn = buttonConfigs[idx]
                const currentBtn = templateUi.buttons?.bottom[idx]
                return {
                  ...currentBtn,
                  color:
                    currentThemeBtn.button_type === "filled"
                      ? currentThemeBtn.filled_button_color
                      : "#",
                  border: {
                    ...currentBtn.border,
                    radius: currentThemeBtn.corner_radius,
                    color: currentThemeBtn.border_color,
                    width: currentThemeBtn.border_width,
                    style: currentThemeBtn.border_style,
                  },
                  text: {
                    ...currentBtn.text,
                    color: currentThemeBtn.button_text.font_color,
                    font: {
                      family: currentThemeBtn.button_text.font_family,
                      size: currentThemeBtn.button_text.font_size,
                      style: currentThemeBtn.button_text.font_style,
                      weight: currentThemeBtn.button_text.font_weight,
                    },
                  },
                }
              }),
            },
            close_button: {
              ...templateUi.close_button,
              color: close_icon_color,
              fill_color: close_icon_fill_color,
              type: close_icon_type,
              height: height,
              width: width,
              shape: close_icon_fill_shape,
            },

            description: {
              ...templateUi.description,
              color: desc_font_color,

              font: {
                family: desc_font_family,
                size: desc_font_size,
                style: desc_font_style,
                weight: desc_font_weight,
              },
              alignment: desc_alignment,
            },
            title: {
              ...templateUi.title,
              color: title_font_color,
              font: {
                family: title_font_family,
                size: title_font_size,
                style: title_font_style,
                weight: title_font_weight,
              },
              alignment: title_alignment,
            },
          }
        default:
          return { ...templateUi }
      }
    }

    // Tooltips
    if (action_class === ACTION_CLASSES.TOOLTIP) {
      switch (layout_type) {
        // Basic Text
        case TOOLTIP_LAYOUT_TYPES.DEFAULT:
          return {
            ...templateUi,
            bg_color: container_color,
            text_config: {
              ...templateUi.text_config,
              color: desc_font_color,
              font_family: desc_font_family,
              size: desc_font_size,
              style: desc_font_style,
            },
            close_button: {
              ...templateUi.close_button,
              color: close_icon_color,
              fill_color: close_icon_fill_color,
              height: height,
              width: width,
              offset_right: offset_x,
              offset_top: offset_y,
              shape: close_icon_fill_shape,
              type: close_icon_type,
            },
            title_config: {
              ...templateUi.title_config,
              color: title_font_color,
              font_family: title_font_family,
              size: title_font_size,
              style: title_font_style,
            },
            buttons_config: Array.from(
              { length: templateUi.buttons_config.length },
              (_, i) => i,
            ).map((_, idx) => {
              const currentThemeBtn = buttonConfigs[idx]
              const currentBtn = templateUi.buttons_config[idx]
              return {
                ...currentBtn,
                color:
                  currentThemeBtn.button_type === "filled"
                    ? currentThemeBtn.filled_button_color
                    : "#",
                borders: {
                  ...currentBtn.borders,
                  radius: currentThemeBtn.corner_radius,
                  stroke_color: currentThemeBtn.border_color,
                  stroke_width: currentThemeBtn.border_width,
                },
                text_config: {
                  ...currentBtn.text_config,
                  color: currentThemeBtn.button_text.font_color,
                  font_family: currentThemeBtn.button_text.font_family,
                  size: currentThemeBtn.button_text.font_size,
                  style: currentThemeBtn.button_text.font_style,
                },
              }
            }),
            corners: {
              ...templateUi.corners,
              width: corner_radius,
            },
          }

        // Basic Text with Image
        case TOOLTIP_LAYOUT_TYPES.IMAGE_TEXT_BUTTONS:
          return {
            ...templateUi,
            bg_color: container_color,
            text_config: {
              ...templateUi.text_config,
              color: desc_font_color,
              font_family: desc_font_family,
              size: desc_font_size,
              style: desc_font_style,
            },
            close_button: {
              ...templateUi.close_button,
              color: close_icon_color,
              fill_color: close_icon_fill_color,
              height: height,
              width: width,
              offset_right: offset_x,
              offset_top: offset_y,
              shape: close_icon_fill_shape,
              type: close_icon_type,
            },
            title_config: {
              ...templateUi.title_config,
              color: title_font_color,
              font_family: title_font_family,
              size: title_font_size,
              style: title_font_style,
            },
            buttons_config: Array.from(
              { length: templateUi.buttons_config.length },
              (_, i) => i,
            ).map((_, idx) => {
              const currentThemeBtn = buttonConfigs[idx]
              const currentBtn = templateUi.buttons_config[idx]
              return {
                ...currentBtn,
                color:
                  currentThemeBtn.button_type === "filled"
                    ? currentThemeBtn.filled_button_color
                    : "#",
                borders: {
                  ...currentBtn.borders,
                  radius: currentThemeBtn.corner_radius,
                  stroke_color: currentThemeBtn.border_color,
                  stroke_width: currentThemeBtn.border_width,
                },
                text_config: {
                  ...currentBtn.text_config,
                  color: currentThemeBtn.button_text.font_color,
                  font_family: currentThemeBtn.button_text.font_family,
                  size: currentThemeBtn.button_text.font_size,
                  style: currentThemeBtn.button_text.font_style,
                },
              }
            }),
            corners: {
              ...templateUi.corners,
              width: corner_radius,
            },
          }

        // Short Menu
        case TOOLTIP_LAYOUT_TYPES.ACTION_BUTTONS:
          return {
            ...templateUi,
            bg_color: container_color,
            text_config: {
              ...templateUi.text_config,
              color: desc_font_color,
              font_family: desc_font_family,
              size: desc_font_size,
              style: desc_font_style,
            },
            close_button: {
              ...templateUi.close_button,
              color: close_icon_color,
              fill_color: close_icon_fill_color,
              height: height,
              width: width,
              offset_right: offset_x,
              offset_top: offset_y,
              shape: close_icon_fill_shape,
              type: close_icon_type,
            },
            title_config: {
              ...templateUi.title_config,
              color: title_font_color,
              font_family: title_font_family,
              size: title_font_size,
              style: title_font_style,
            },
            action_buttons_config: Array.from(
              { length: templateUi.action_buttons_config.length },
              (_, i) => i,
            ).map((_, idx) => {
              const currentThemeBtn = buttonConfigs[idx]
              const currentBtn = templateUi.action_buttons_config[idx]
              return {
                ...currentBtn,
                color: currentThemeBtn.filled_button_color,
                borders: {
                  ...currentBtn.borders,
                  radius: currentThemeBtn.corner_radius,
                  stroke_color: currentThemeBtn.border_color,
                  stroke_width: currentThemeBtn.border_width,
                },
                text_config: {
                  ...currentBtn.text_config,
                  color: title_font_color,
                  font_family: title_font_family,
                  style: title_font_style,
                  size: title_font_size,
                },
                description_config: {
                  ...currentBtn.description_config,
                  color: desc_font_color,
                  font_family: desc_font_family,
                  style: desc_font_style,
                  size: desc_font_size,
                },
              }
            }),
            corners: {
              ...templateUi.corners,
              width: corner_radius,
            },
          }
        default:
          return {
            ...templateUi,
          }
      }
    }

    // Nudge
    if (action_class === ACTION_CLASSES.NUDGE) {
      switch (display_type) {
        case BADGE_DISPLAY_TYPES.LEGACY:
          return { ...templateUi }
        default:
          return {
            ...templateUi,
            badge: {
              ...templateUi.badge,
              background: {
                ...templateUi.badge.background,
                shape: {
                  ...templateUi.badge.background.shape,
                  color: container_color,
                  border: {
                    ...templateUi.badge.background.shape.border,
                    color: stroke_color,
                    width: {
                      top: stroke_width,
                      left: stroke_width,
                      right: stroke_width,
                      bottom: stroke_width,
                    },
                  },
                },
              },
              content: Array.from(
                {
                  length: templateUi.badge.content
                    ? templateUi.badge.content.length
                    : 0,
                },
                (_, i) => i,
              ).map((_, idx) => {
                const currentContent = templateUi.badge.content[idx]
                return {
                  ...currentContent,
                  label: {
                    ...currentContent.label,
                    color: title_font_color,
                    alignment: title_alignment,
                    font: {
                      family: title_font_family,
                      size: title_font_size,
                      style: title_font_style,
                      weight: title_font_weight,
                    },
                  },
                }
              }),
            },
          }
      }
    }

    // Coachmark
    if (action_class === ACTION_CLASSES.COACHMARK) {
      switch (display_type) {
        case COACHMARK_DISPLAY_TYPES.LEGACY:
          return {
            ...templateUi,
            bg_color: container_color,

            text_config: {
              ...templateUi.text_config,
              color: desc_font_color,
              font_family: desc_font_family,
              size: desc_font_size,
              style: desc_font_style,
            },

            buttons_config: Array.from(
              { length: templateUi.buttons_config.length },
              (_, i) => i,
            ).map((_, idx) => {
              const currentThemeBtn = buttonConfigs[idx]
              const currentBtn = templateUi.buttons_config[idx]
              return {
                ...currentBtn,
                color:
                  currentThemeBtn.button_type === "filled"
                    ? currentThemeBtn.filled_button_color
                    : "#",
                borders: {
                  ...currentBtn.borders,
                  radius: currentThemeBtn.corner_radius,
                  stroke_color: currentThemeBtn.border_color,
                  stroke_width: currentThemeBtn.border_width,
                },
                text_config: {
                  ...currentBtn.text_config,
                  color: currentThemeBtn.button_text.font_color,
                  font_family: currentThemeBtn.button_text.font_family,
                  size: currentThemeBtn.button_text.font_size,
                  style: currentThemeBtn.button_text.font_style,
                },
              }
            }),
          }

        default:
          return {
            ...templateUi,
            outer_highlighter: {
              ...templateUi.outer_highlighter,

              color: container_color,
            },
            content: {
              ...templateUi.content,
              coachmark_buttons: {
                ...templateUi.content.coachmark_buttons,
                list: Array.from(
                  { length: templateUi.content.coachmark_buttons.list.length },
                  (_, i) => i,
                ).map((_, idx) => {
                  const currentThemeBtn = buttonConfigs[idx]
                  const currentBtn =
                    templateUi.content.coachmark_buttons.list[idx]
                  return {
                    ...currentBtn,
                    color:
                      currentThemeBtn.button_type === "filled"
                        ? currentThemeBtn.filled_button_color
                        : "#",
                    border: {
                      ...currentBtn.border,
                      radius: currentThemeBtn.corner_radius,
                      color: currentThemeBtn.border_color,
                      width: currentThemeBtn.border_width,
                      style: currentThemeBtn.border_style,
                    },
                    text: {
                      ...currentBtn.text,
                      color: currentThemeBtn.button_text.font_color,
                      font: {
                        family: currentThemeBtn.button_text.font_family,
                        size: currentThemeBtn.button_text.font_size,
                        style: currentThemeBtn.button_text.font_style,
                        weight: currentThemeBtn.button_text.font_weight,
                      },
                    },
                  }
                }),
              },
              description: {
                ...templateUi.content.description,
                color: desc_font_color,
                font: {
                  family: desc_font_family,
                  size: desc_font_size,
                  style: desc_font_style,
                  weight: desc_font_weight,
                },
              },
              title: {
                ...templateUi.content.title,
                color: title_font_color,
                font: {
                  family: title_font_family,
                  size: title_font_size,
                  style: title_font_style,
                  weight: title_font_weight,
                },
              },
            },
          }
      }
    }

    // Inline
    if (action_class === ACTION_CLASSES.INLINE) {
      switch (layout_type) {
        case "modal":
        default:
          return {
            ...templateUi,
          }
      }
    }
    return { ...templateUi }
  } else {
    // Onboarding Tooltips
    if (layout_type === ONBOARDING_TOOLTIP_LAYOUT_TYPES.CAROUSAL_BUTTONS) {
      return {
        ...templateUi,
        dim_background_color: container_color,
        dimbackground: {
          ...templateUi.dimbackground,
          color: container_color,
        },
        bg_color: container_color,
        carousal: { ...templateUi.carousal, d_acolor: title_font_color },
        next: {
          ...templateUi.next,
          color: buttonConfigs[0].button_text.font_color,
          bg_color:
            buttonConfigs[0].button_type === "filled"
              ? buttonConfigs[0].filled_button_color
              : "",
          font_family: buttonConfigs[0].button_text.font_family,
          size: buttonConfigs[0].button_text.font_size,
          style: buttonConfigs[0].button_text.font_style,
          border_radius: buttonConfigs[0].corner_radius,
        },
        prev: {
          ...templateUi.prev,
          color: buttonConfigs[0].button_text.font_color,

          bg_color:
            buttonConfigs[0].button_type === "filled"
              ? buttonConfigs[0].filled_button_color
              : "",
          font_family: buttonConfigs[0].button_text.font_family,
          size: buttonConfigs[0].button_text.font_size,
          style: buttonConfigs[0].button_text.font_style,
          border_radius: buttonConfigs[0].corner_radius,
        },
        title_config: {
          ...templateUi.title_config,
          color: title_font_color,
          font_family: title_font_family,
          size: title_font_size,
          style: title_font_style,
        },
        text_config: {
          ...templateUi.text_config,
          color: desc_font_color,
          font_family: desc_font_family,
          size: desc_font_size,
          style: desc_font_style,
        },
      }
    }

    // Onboarding Spotlights

    return {
      ...templateUi,
      bg_color: container_color,
      dim_background_color: container_color,
      dimbackground: {
        ...templateUi.dimbackground,
        color: container_color,
      },
      carousal: { ...templateUi.carousal, d_acolor: title_font_color },
      next: {
        ...templateUi.next,
        color: buttonConfigs[0].button_text.font_color,
        bg_color:
          buttonConfigs[0].button_type === "filled"
            ? buttonConfigs[0].filled_button_color
            : "",
        font_family: buttonConfigs[0].button_text.font_family,
        size: buttonConfigs[0].button_text.font_size,
        style: buttonConfigs[0].button_text.font_style,
        border_radius: buttonConfigs[0].corner_radius,
      },
      prev: {
        ...templateUi.prev,
        color: buttonConfigs[0].button_text.font_color,

        bg_color:
          buttonConfigs[0].button_type === "filled"
            ? buttonConfigs[0].filled_button_color
            : "",
        font_family: buttonConfigs[0].button_text.font_family,
        size: buttonConfigs[0].button_text.font_size,
        style: buttonConfigs[0].button_text.font_style,
        border_radius: buttonConfigs[0].corner_radius,
      },
      title_config: {
        ...templateUi.title_config,
        color: title_font_color,
        font_family: title_font_family,
        size: title_font_size,
        style: title_font_style,
      },
      text_config: {
        ...templateUi.text_config,
        color: desc_font_color,
        font_family: desc_font_family,
        size: desc_font_size,
        style: desc_font_style,
      },
    }
  }
}

export function getAdvancedViewFindingConfig(
  view,
  find_config = {
    parent_find_config: {},
  },
  isContentViewSelectionMode = false,
) {
  if (isContentViewSelectionMode) {
    find_config = {
      search_criteria: [SEARCH_CRITERIA_TYPES.CONTENT],
      eval: {
        indexes: [-1, -1],
        t: "s",
        val: view?.additional_info?.content,
        op: "EQ",
      },
      nth_level_parent: view?.depth ?? 0,
      parent_find_config: find_config?.parent_find_config,
    }
  } else {
    // Step 1 : Parent Config
    if (view?.additional_info?.closest_parent_tag) {
      const elemsWithSameCpTag = document.querySelectorAll(
        `[data-cp-tag="${view?.additional_info?.closest_parent_tag}"]`,
      )

      if (elemsWithSameCpTag.length === 1) {
        find_config.parent_find_config = {
          search_criteria: [SEARCH_CRITERIA_TYPES.TAG],
          view_tag: view?.additional_info?.closest_parent_tag,
        }
      }
    }

    if (
      !view?.additional_info?.closest_parent_tag &&
      view?.additional_info?.closest_parent_id
    ) {
      const elemsWithSameCpId = document.querySelectorAll(
        `[data-cp-id="${view?.additional_info?.closest_parent_id}"]`,
      )

      if (elemsWithSameCpId.length === 1) {
        find_config.parent_find_config = {
          search_criteria: [SEARCH_CRITERIA_TYPES.ID],
          view_id: view?.additional_info?.closest_parent_id,
        }
      }
    }

    // Step 2 : Tag
    if (view?.tag) {
      const elemsWithSameTag = document.querySelectorAll(
        `[data-tag="${view?.tag}"]`,
      )

      if (elemsWithSameTag.length === 1) {
        find_config = {
          search_criteria: [SEARCH_CRITERIA_TYPES.TAG],
          view_tag: view?.tag,
          parent_find_config: find_config?.parent_find_config,
        }
      }
    }

    // Step 3 : ID
    if (!find_config?.search_criteria && view?.id) {
      const elemsWithSameId = document.querySelectorAll(
        `[data-id="${view?.id}"]`,
      )

      if (elemsWithSameId.length === 1) {
        find_config = {
          search_criteria: [SEARCH_CRITERIA_TYPES.ID],
          view_id: view?.id,
          parent_find_config: find_config?.parent_find_config,
        }
      }
    }

    // Step 4 : Immediate Parent & Index
    if (!find_config?.search_criteria) {
      // Step 4.1 : Check if there's a parent_tag or parent_id
      if (
        view?.additional_info?.parent_tag ||
        view?.additional_info?.parent_id
      ) {
        if (view?.additional_info?.parent_tag) {
          find_config.parent_find_config = {
            search_criteria: [SEARCH_CRITERIA_TYPES.TAG],
            view_tag: view?.additional_info?.parent_tag,
          }
        }

        if (
          !view?.additional_info?.parent_tag &&
          view?.additional_info?.parent_id
        ) {
          find_config.parent_find_config = {
            search_criteria: [SEARCH_CRITERIA_TYPES.ID],
            view_id: view?.additional_info?.parent_id,
          }
        }

        find_config = {
          search_criteria: [SEARCH_CRITERIA_TYPES.INDEX],
          view_index: view?.additional_info?.index,
          parent_find_config: find_config?.parent_find_config,
        }
      } else {
        // Step 4.2 : Fallback to `relative_path`
        find_config = {
          search_criteria: [SEARCH_CRITERIA_TYPES.PATH],
          view_path:
            Object.keys(find_config?.parent_find_config).length > 0
              ? view?.additional_info?.relative_path
              : (view?.additional_info?.path ?? view?.path),
          parent_find_config: find_config?.parent_find_config,
        }
      }
    }
  }

  return find_config
}

export function checkIfViewContainsContentViews(view, dispatch) {
  const views = view?.views ?? []
  let atleast_one_content_view = view?.additional_info?.content ? true : false

  if (atleast_one_content_view) {
    flushSync(() => {
      dispatch({
        type: SET_VIEW_MAP,
        key: view.path,
        value: {
          view: { ...view, depth: 0 },
        },
      })
    })
  }

  const traverse = (views, depth = 1) => {
    views.forEach((eachView) => {
      if (eachView?.additional_info?.content) {
        atleast_one_content_view = true
        flushSync(() => {
          dispatch({
            type: SET_VIEW_MAP,
            key: eachView.path,
            value: {
              view: { ...eachView, depth },
            },
          })
        })
      }
      if (eachView?.views?.length > 0) {
        traverse(eachView?.views, depth + 1)
      }
    })
  }

  traverse(views)

  return atleast_one_content_view
}

export function findIfSelectedSelectorIsUnique(view) {
  const { tag, id, path } = view

  const elemsWithSameTag = document.querySelectorAll(`[data-tag="${tag}"]`)
  const elemsWithSameId = document.querySelectorAll(`[data-id="${id}"]`)
  const elemsWithSamePath = document.querySelectorAll(
    `[data-identifier="${path}"]`,
  )

  if (tag && tag !== "") {
    return elemsWithSameTag.length === 0 || elemsWithSameTag.length === 1
  } else if (!tag && id && id !== "") {
    return elemsWithSameId.length === 0 || elemsWithSameId.length === 1
  } else {
    return elemsWithSamePath.length === 0 || elemsWithSamePath.length === 1
  }
}
